import React from 'react';
import styled from 'styled-components';
import { HowFenaTerminalWorksPageContent } from '@/pages/how-fena-terminal-works';
import FirstViewport from '@/components/FirstViewport';
import Section from '@/components/Section';
import {
  Description,
  H1,
  H2,
  H3,
  P,
  Strong,
  Subtitle5,
} from '@/components/Typography';
import viewport1img from '../assets/img/page19viewport1.png';
import page19Vp1Bg from '../assets/img/page4Vp1Bg.png';
import gradientBg from '../assets/img/gradientBg.png';
import bannerBg from '../assets/img/bannerFenaTerminal.png';
import ButtonDefault from '@/components/ButtonDefault';
import StepCard from '@/components/StepCard';
import SpecificationCard from '@/components/SpecificationCard';
import VideoHowFenaTerminalWorks from '@/components/VideoHowFenaTerminalWorks';
import BottomMessageType2 from '@/components/BottomMessageType2';

const FirstViewportWrapper = styled.div`
  background-image: ${(props) => `url(${props.resource})`};
  background-repeat: no-repeat;
  background-size: cover;
`;
const ViewportsWrapper = styled.div`
  background-image: ${(props) => `url(${props.resource})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #ffff;
`;
const SecondViewport = styled(Section)`
  background-color: #ffff;
`;
const SectionTitle = styled(H2)`
  margin: 1rem 0 1rem 0;
`;
const StepCardWrapper = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
  margin-top: 2rem;
  flex-wrap: nowrap;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
`;
const ThirdViewport = styled(Section)``;
const SectionDescriptionP = styled(P)`
  width: 50%;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;
const PaymentMethodWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 1200px) {
    margin-top: 1rem;
  }
`;
const PaymentMethod = styled.div`
  margin-right: 2rem;
  margin-top: 2rem;
  @media (max-width: 1200px) {
    margin-right: 1rem;
    margin-top: 1rem;
  }
`;
const Img = styled.img``;
const FourthViewport = styled(Section)``;
const SpecCardsWrapper = styled.div`
  display: flex;
  margin-top: 2rem;
  flex-wrap: nowrap;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
`;

const FifthViewport = styled(Section)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  height: calc(100vh - 70px - 5rem * 2);
  padding-top: 5rem;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
    height: auto;
    padding-bottom: 0;
  }
`;

const LeftSideContent = styled.div`
  flex: 1 1 50%;
  padding-right: 1rem;
`;
const DescriptionSection = styled.div`
  margin-top: 2rem;
`;
const DescriptionItem = styled.div`
  display: flex;
`;
const Circle = styled.div`
  border: 2px solid #6c6c8a;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  flex-shrink: 0;
  flex-grow: 0;
`;
const Dash = styled.div`
  border: 1px dashed #c2cedb;
  height: 100%;
`;
const CircleAndDash = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1rem;
`;
const Step = styled(Strong)`
  margin-bottom: 1rem;
`;
const Vp5Text = styled.div``;

const DescriptionDashed = styled(P)`
  margin-top: -0.4rem;
  margin-bottom: 2rem;
`;

const RightSideContent = styled.div`
  max-height: 100%;
  padding-left: 1rem;
  flex: 1 1 50%;
  @media (max-width: 1200px) {
    max-height: auto;
    width: 100%;
    margin-top: 2rem;
    padding-left: 0;
  }
`;
const InfoBox = styled.div`
  background-color: #f4f7f9;
  border: 1px solid #dbe3eb;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
`;
const BoxTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;
const InfoCircle = styled.div`
  background-color: #38b6ff;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const I = styled.div`
  padding-top: 0.2rem;
  color: white;
  font-weight: 600;
`;
const SixthViewport = styled(Section)`
  padding-top: 0;
  margin-bottom: 5rem;
`;

interface HowFenaTerminalWorksPageProps {
  content: HowFenaTerminalWorksPageContent;
}

const HowFenaTerminalWorksContainer: React.FunctionComponent<
  HowFenaTerminalWorksPageProps
> = ({
  content: { viewport1, viewport2, viewport3, viewport4, viewport5, viewport6 },
}) => (
  <>
    <FirstViewportWrapper resource={page19Vp1Bg}>
      <FirstViewport withRightPadding img={viewport1img}>
        <H1>
          {viewport1.title1}
          {` `}
          <span className="accent-text"> {viewport1.title2}</span>
          {` `}
          {viewport1.title3}
        </H1>
        <Description>{viewport1.description}</Description>
        <ButtonDefault to={viewport1.button.url}>
          {viewport1.button.label}
        </ButtonDefault>
      </FirstViewport>
    </FirstViewportWrapper>
    <ViewportsWrapper resource={gradientBg}>
      <SecondViewport>
        <Strong className="accent-text">{viewport2.label}</Strong>
        <SectionTitle>{viewport2.title}</SectionTitle>
        <StepCardWrapper>
          {viewport2.stepCards.map((item) => (
            <StepCard
              key={item.label}
              label={item.label}
              description={item.description}
            />
          ))}
        </StepCardWrapper>
      </SecondViewport>
      <ThirdViewport>
        <SectionTitle>{viewport3.title}</SectionTitle>
        <SectionDescriptionP>{viewport3.description}</SectionDescriptionP>
        <PaymentMethodWrapper>
          {viewport3.paymentMethods.map((item) => (
            <PaymentMethod key={item.img}>
              <div>
                <Img src={item.img} />
              </div>
            </PaymentMethod>
          ))}
        </PaymentMethodWrapper>
      </ThirdViewport>
      <FourthViewport>
        <H3>{viewport4.title}</H3>
        <SpecCardsWrapper>
          {viewport4.specCards.map((item) => (
            <SpecificationCard
              key={item.title}
              title={item.title}
              text1={item.description1}
              text2={item.description2}
              cardImgSrc={item.img}
            />
          ))}
        </SpecCardsWrapper>
      </FourthViewport>

      <FifthViewport>
        <LeftSideContent>
          <Strong className="accent-text">{viewport5.label}</Strong>
          <SectionTitle>{viewport5.title}</SectionTitle>
          <DescriptionSection>
            <DescriptionItem>
              <CircleAndDash>
                <Circle />
                <Dash />
              </CircleAndDash>
              <Vp5Text>
                <Step className="accent-text">{viewport5.subLabel1}</Step>
                <DescriptionDashed>
                  {viewport5.subDescription1}
                </DescriptionDashed>
              </Vp5Text>
            </DescriptionItem>
            <DescriptionItem>
              <CircleAndDash>
                <Circle />
                <Dash />
              </CircleAndDash>
              <Vp5Text>
                <Step className="accent-text">{viewport5.subLabel2}</Step>
                <DescriptionDashed>
                  {viewport5.subDescription2_1}
                  {` `}
                  <span className="accent-text-black-bold">
                    {viewport5.subDescription2_2}
                  </span>
                </DescriptionDashed>
              </Vp5Text>
            </DescriptionItem>
            <DescriptionItem>
              <CircleAndDash>
                <Circle />
                <Dash />
              </CircleAndDash>
              <Vp5Text>
                <Step className="accent-text">{viewport5.subLabel3}</Step>
                <DescriptionDashed>
                  {viewport5.subDescription3_1}
                  {` `}
                  <span className="accent-text-black-bold">
                    {viewport5.subDescription3_2}
                  </span>
                  {` `}
                  {viewport5.subDescription3_3}
                  {` `}
                  <span className="accent-text-black-bold">
                    {viewport5.subDescription3_4}
                  </span>
                </DescriptionDashed>
              </Vp5Text>
            </DescriptionItem>
            <DescriptionItem>
              <CircleAndDash>
                <Circle />
              </CircleAndDash>
              <Vp5Text>
                <Step className="accent-text">{viewport5.subLabel4}</Step>
                <DescriptionDashed>
                  {viewport5.subDescription4}
                </DescriptionDashed>
              </Vp5Text>
            </DescriptionItem>
          </DescriptionSection>
        </LeftSideContent>
        <RightSideContent>
          <InfoBox>
            <BoxTitle>
              <Subtitle5>{viewport5.infoBox.label}</Subtitle5>
              <InfoCircle>
                <I>i</I>
              </InfoCircle>
            </BoxTitle>
            <P>
              {viewport5.infoBox.description1}
              {` `}
              <span className="accent-text-blue-bold">
                {viewport5.infoBox.description2}
              </span>
            </P>
          </InfoBox>
          <VideoHowFenaTerminalWorks
            videoSrcURL="https://www.youtube.com/embed/eoZcVfaNbLs"
            videoTitle="title"
          />
        </RightSideContent>
      </FifthViewport>
      <SixthViewport>
        <BottomMessageType2
          bannerLabel1={viewport6.bannerLabel1}
          bannerLabel2={viewport6.bannerLabel2}
          bannerLabel3={viewport6.bannerLabel3}
          bannerDescription={viewport6.bannerDescription}
          button={viewport6.button}
          bannerBg={bannerBg}
        />
      </SixthViewport>
    </ViewportsWrapper>
  </>
);

export default HowFenaTerminalWorksContainer;
