import React from 'react';
import Main from '../containers/Layout';
import howFenaTerminalWorksContent from '../../content/pages/how-fena-terminal-works.yml';
import HowFenaTerminalWorksContainer from '@/containers/How-fena-terminal-works';

export interface HowFenaTerminalWorksPageContent {
  viewport1: {
    title1: string;
    title2: string;
    title3: string;
    description: string;
    button: {
      label: string;
      url: string;
    };
  };
  viewport2: {
    label: string;
    title: string;
    stepCards: {
      label: string;
      description: string;
    }[];
  };
  viewport3: {
    title: string;
    description: string;
    paymentMethods: { img: string }[];
  };
  viewport4: {
    title: string;
    specCards: {
      img: string;
      title: string;
      description1: string;
      description2: string;
    }[];
  };
  viewport5: {
    label: string;
    title: string;
    subLabel1: string;
    subDescription1: string;
    subLabel2: string;
    subDescription2_1: string;
    subDescription2_2: string;
    subLabel3: string;
    subDescription3_1: string;
    subDescription3_2: string;
    subDescription3_3: string;
    subDescription3_4: string;
    subLabel4: string;
    subDescription4: string;
    infoBox: {
      label: string;
      description1: string;
      description2: string;
    };
  };
  viewport6: {
    bannerLabel1: string;
    bannerLabel2: string;
    bannerLabel3: string;
    bannerDescription: string;
    button: {
      label: string;
      url: string;
    };
  };
}
const AcceptPaymentsAccountToAccount: React.FunctionComponent = () => {
  const { viewport1, viewport2, viewport3, viewport4, viewport5, viewport6 } =
    howFenaTerminalWorksContent as unknown as HowFenaTerminalWorksPageContent;
  return (
    <Main>
      <HowFenaTerminalWorksContainer
        content={{
          viewport1,
          viewport2,
          viewport3,
          viewport4,
          viewport5,
          viewport6,
        }}
      />
    </Main>
  );
};

export default AcceptPaymentsAccountToAccount;
