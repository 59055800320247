import React from 'react';
import styled from 'styled-components';
import ButtonDefault from './ButtonDefault';
import { H2, P } from './Typography';

const LowerTextWrapper = styled.div`
  display: flex;
  background-image: ${(props) => `url(${props.resource})`};
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  border-radius: 20px;
  margin-top: 5rem;
  height: 30vh;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 1200px) {
    height: auto;
    padding: 2rem 1rem;
  }
`;
const TitleH2 = styled(H2)`
  text-align: center;
`;
const LowerTextDescription = styled(P)`
  width: 60%;
  text-align: center;
  margin: 2rem 0;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const Span = styled.span<{ colorText?: string }>`
  color: ${(props) => (props.colorText ? `${props.colorText}` : `#2CD19E`)};
`;

const Link = styled.a`
  text-decoration: none;
`;
interface ComponentProps {
  bannerLabel1: string;
  bannerLabel2?: string;
  bannerLabel3?: string;
  bannerDescription: string;
  bannerDescription2?: string;
  bannerDescription3?: string;
  bannerBg: string;
  button: {
    label: string;
    url: string;
  };
  accentTextColor?: string;
}
const BottomMessageType2: React.FunctionComponent<ComponentProps> = ({
  bannerLabel1,
  bannerLabel2,
  bannerLabel3,
  bannerDescription,
  bannerDescription2,
  bannerDescription3,
  button,
  bannerBg,
  accentTextColor,
}) => (
  <LowerTextWrapper resource={bannerBg}>
    <TitleH2>
      {bannerLabel1}&nbsp;
      <Span colorText={accentTextColor}>{bannerLabel2}</Span>&nbsp;
      {bannerLabel3}
    </TitleH2>
    <LowerTextDescription>
      {bannerDescription}
      {` `}
      <Link href="mailto:partership@fena.co">
        <span className="accent-text-black-bold">{bannerDescription2}</span>
      </Link>
      {` `}
      {bannerDescription3}
    </LowerTextDescription>
    <ButtonDefault to={button.url}>{button.label}</ButtonDefault>
  </LowerTextWrapper>
);

export default BottomMessageType2;
